// frontend/src/components/AdminDashboard.js

import React, { useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const AdminDashboard = () => {
  const { auth } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    author: '',
    content: '',
    excerpt: '',
    tags: '',
    category: 'Technology',
    featuredImage: '',
    published: false,
  });

  const { title, slug, author, content, excerpt, tags, category, featuredImage, published } = formData;

  const onChange = e => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const onSubmit = async e => {
    e.preventDefault();

    // Split tags by comma and trim spaces
    const tagsArray = tags.split(',').map(tag => tag.trim());

    const postData = {
      title,
      slug,
      author,
      content,
      excerpt,
      tags: tagsArray,
      category,
      featuredImage,
      published,
    };

    try {
      const res = await axios.post('/api/posts', postData, {
        headers: {
          'x-auth-token': auth.token,
          'Content-Type': 'application/json',
        },
      });
      alert('Post created successfully!');
      // Reset form
      setFormData({
        title: '',
        slug: '',
        author: '',
        content: '',
        excerpt: '',
        tags: '',
        category: 'Technology',
        featuredImage: '',
        published: false,
      });
    } catch (err) {
      console.error(err);
      alert('Error creating post. Please check the console for details.');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Create New Blog Post</h2>
      <form onSubmit={onSubmit}>
        {/* Title */}
        <div className="mb-4">
          <label className="block mb-2">Title:</label>
          <input 
            type="text" 
            name="title" 
            value={title}
            onChange={onChange}
            required 
            className="w-full border px-3 py-2"
          />
        </div>

        {/* Slug */}
        <div className="mb-4">
          <label className="block mb-2">Slug (URL-friendly):</label>
          <input 
            type="text" 
            name="slug" 
            value={slug}
            onChange={onChange}
            required 
            className="w-full border px-3 py-2"
            placeholder="e.g., my-first-post"
          />
        </div>

        {/* Author */}
        <div className="mb-4">
          <label className="block mb-2">Author:</label>
          <input 
            type="text" 
            name="author" 
            value={author}
            onChange={onChange}
            required 
            className="w-full border px-3 py-2"
            placeholder="Your Name"
          />
        </div>

        {/* Content */}
        <div className="mb-4">
          <label className="block mb-2">Content:</label>
          <textarea 
            name="content" 
            value={content}
            onChange={onChange}
            required 
            className="w-full border px-3 py-2 h-40"
            placeholder="Write your blog content here..."
          ></textarea>
        </div>

        {/* Excerpt */}
        <div className="mb-4">
          <label className="block mb-2">Excerpt:</label>
          <textarea 
            name="excerpt" 
            value={excerpt}
            onChange={onChange}
            required 
            className="w-full border px-3 py-2 h-20"
            placeholder="Short summary of the post..."
          ></textarea>
        </div>

        {/* Tags */}
        <div className="mb-4">
          <label className="block mb-2">Tags (comma-separated):</label>
          <input 
            type="text" 
            name="tags" 
            value={tags}
            onChange={onChange}
            className="w-full border px-3 py-2"
            placeholder="e.g., technology, programming"
          />
        </div>

        {/* Category */}
        <div className="mb-4">
          <label className="block mb-2">Category:</label>
          <select 
            name="category" 
            value={category}
            onChange={onChange}
            className="w-full border px-3 py-2"
          >
            <option value="Technology">Technology</option>
            <option value="Politics">Politics</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* Featured Image */}
        <div className="mb-4">
          <label className="block mb-2">Featured Image URL:</label>
          <input 
            type="text" 
            name="featuredImage" 
            value={featuredImage}
            onChange={onChange}
            className="w-full border px-3 py-2"
            placeholder="http://example.com/image.jpg"
          />
        </div>

        {/* Publish */}
        <div className="mb-4 flex items-center">
          <input 
            type="checkbox" 
            name="published" 
            checked={published}
            onChange={onChange}
            className="mr-2"
          />
          <label>Publish Immediately</label>
        </div>

        {/* Submit Button */}
        <button type="submit" className="bg-blue-600 text-white px-4 py-2">Create Post</button>
      </form>
    </div>
  );
};

export default AdminDashboard;
