// frontend/src/components/BlogPost.js

import React, { useEffect, useState, useContext } from 'react';
import { getPostBySlug, getRelatedPosts } from '../services/api';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkToc from 'remark-toc';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import { AuthContext } from '../context/AuthContext'; // If you want to allow admin edits, etc.

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch post data
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data = await getPostBySlug(slug);
        setPost(data);
      } catch (error) {
        console.error("Error fetching the post:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
  }, [slug]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="container mx-auto p-6">
        <h2 className="text-2xl font-bold">Post Not Found</h2>
        <p>The post you're looking for does not exist.</p>
        <Link to="/news" className="text-blue-600 hover:underline mt-4 block">
          ← Back to News
        </Link>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>{post.title} | YourWebsiteName</title>
        <meta name="description" content={post.excerpt} />
        {/* Add more meta tags as needed */}
      </Helmet>

      {/* Featured Image */}
      {post.featuredImage && (
        <img 
          src={post.featuredImage} 
          alt={`Featured image for ${post.title}`} 
          className="w-full h-64 md:h-96 object-cover rounded-lg mb-6"
          loading="lazy"
        />
      )}

      {/* Title and Metadata */}
      <h1 className="text-4xl md:text-5xl font-bold mb-2">{post.title}</h1>
      <div className="flex justify-between items-center text-sm text-gray-500 mb-6">
        <span>By {post.author}</span>
        <span>{new Date(post.publishedAt).toLocaleDateString()}</span>
      </div>

      {/* Table of Contents (Optional) */}
      {post.content.includes('[toc]') && (
        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Table of Contents</h2>
          <ReactMarkdown 
            remarkPlugins={[remarkGfm, remarkToc]}
            className="prose"
          >
            {post.content}
          </ReactMarkdown>
        </div>
      )}

      {/* Content */}
      <div className="prose max-w-none mb-8">
        <ReactMarkdown 
          remarkPlugins={[remarkGfm, remarkToc]}
          components={{
            code({node, inline, className, children, ...props}) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter 
                  style={coy} 
                  language={match[1]} 
                  PreTag="div" 
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            // Add more custom components if needed
          }}
        >
          {post.content}
        </ReactMarkdown>
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between mb-12">
        <Link to="/news" className="text-blue-600 hover:underline flex items-center">
          ← Back to News
        </Link>
        {/* Future: Add Next/Previous Post Navigation */}
      </div>

      
    </div>
  );
};

export default BlogPost;
