// frontend/src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './components/Hero';
import Bio from './components/Bio';
import Footer from './components/Footer';
import Header from './components/Header'; 
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import NotFound from './components/NotFound';
import AdminDashboard from './components/AdminDashboard';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';

const Home = () => (
  <>
    <Hero />
    <Bio />
    {/* Add other home page components here */}
  </>
);

function App() {
  return (
    <Router>
      <div className="bg-white text-gray-900 min-h-screen flex flex-col">
        {/* Navigation Bar */}
        <Header />

        {/* Main Content */}
        <main className="flex-grow">
          <Routes>
            {/* Home Route */}
            <Route path="/" element={<Home />} />

            {/* Blog Routes */}
            <Route path="/news" element={<BlogList />} />
            <Route path="/news/:slug" element={<BlogPost />} />

            {/* Admin Routes */}
            <Route path="/admin" element={<PrivateRoute />}>
              <Route path="/admin" element={<AdminDashboard />} />
            </Route>
            <Route path="/login" element={<Login />} />

            {/* 404 Not Found Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
