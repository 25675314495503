// components/Header.js
import React from 'react';

function Header() {
  return (
    <header className="flex items-center justify-between px-8 py-4 border-b border-gray-200">
      <nav className="space-x-6">
        <a href="/" className="hover:text-blue-600">Home</a>
        <a href="#bio" className="hover:text-blue-600">About</a>
        <a href="/news" className="hover:text-blue-600">News</a>
      </nav>
    </header>
  );
}

export default Header;
