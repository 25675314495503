// components/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="py-8 text-center border-t border-gray-200">
      <p className="text-gray-500">&copy; {new Date().getFullYear()} Zachery Annis. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
