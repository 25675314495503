// frontend/src/components/Login.js

import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  
  const { username, password } = formData;
  
  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
  
  const onSubmit = async e => {
    e.preventDefault();
    try {
      const res = await axios.post('/api/auth/login', { username, password });
      login(res.data.token);
      navigate('/admin');
    } catch (err) {
      console.error(err);
      alert('Invalid Credentials');
    }
  };
  
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Admin Login</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <label className="block mb-2">Username:</label>
          <input 
            type="text" 
            name="username" 
            value={username}
            onChange={onChange}
            required 
            className="w-full border px-3 py-2"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Password:</label>
          <input 
            type="password" 
            name="password" 
            value={password}
            onChange={onChange}
            required 
            className="w-full border px-3 py-2"
          />
        </div>
        <button type="submit" className="bg-blue-600 text-white px-4 py-2">Login</button>
      </form>
    </div>
  );
};

export default Login;
