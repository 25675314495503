// frontend/src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    loading: true,
    user: null,
  });

  useEffect(() => {
    const loadUser = async () => {
      if (auth.token) {
        try {
          // Optionally, fetch user data here
          setAuth(prevState => ({
            ...prevState,
            isAuthenticated: true,
            loading: false,
          }));
        } catch (err) {
          console.error(err);
          localStorage.removeItem('token');
          setAuth({
            token: null,
            isAuthenticated: false,
            loading: false,
            user: null,
          });
        }
      } else {
        setAuth(prevState => ({
          ...prevState,
          loading: false,
        }));
      }
    };
    loadUser();
  }, [auth.token]);

  const login = (token) => {
    localStorage.setItem('token', token);
    setAuth({
      token,
      isAuthenticated: true,
      loading: false,
      user: null,
    });
  };

  const logout = () => {
    localStorage.removeItem('token');
    setAuth({
      token: null,
      isAuthenticated: false,
      loading: false,
      user: null,
    });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
