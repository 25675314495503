// frontend/src/services/api.js

import axios from '../utils/axiosConfig';

export const getAllPosts = async () => {
  const response = await axios.get('/api/posts');
  return response.data;
};

/**
 * Fetches related posts based on shared category or tags.
 * @param {string} slug - The slug of the current post.
 * @returns {Promise<Array>} - A promise that resolves to an array of related posts.
 */
export const getRelatedPosts = async (slug) => {
    try {
      const response = await axios.get(`/api/posts/related/${slug}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching related posts:", error);
      throw error;
    }
  };

export const getPostBySlug = async (slug) => {
  const response = await axios.get(`/api/posts/${slug}`);
  return response.data;
};

// Admin functions
export const createPost = async (postData) => {
  const response = await axios.post('/api/posts', postData);
  return response.data;
};

export const updatePost = async (id, postData) => {
  const response = await axios.put(`/api/posts/${id}`, postData);
  return response.data;
};

export const deletePost = async (id) => {
  const response = await axios.delete(`/api/posts/${id}`);
  return response.data;
};
